<template>
  <div class="fine-music-list" ref="item">
    <div class="music-item">
      <router-link  target="_blank" class="w-100" :to="`/playListDetail/${item.playlist_id}`">
        <div class="img-all-box-a enlarge" :style="imgBg(item.logo_url)">
          <!-- <ng-image class="music-img" :src="item.logo_url"></ng-image> -->
          <div class="music-play-btn">
            <icon-font type="iconplay-fff" />
          </div>
          <div class="lable-wrap">
            <span
              class="lable-item"
              v-for="lable in item && item.labels"
              :key="lable.id"
              >{{ lable && lable.name }}</span
            >
          </div>
          <div
            class="collect-icon"
            :class="{ active: item.is_collected == '1' }"
            @click.prevent="handleCollect(item)"
          >
            <icon-font v-if="item.is_collected == '1'" type="iconheart" />
            <icon-font v-else type="iconheart-empty" />
          </div>
        </div>
        <div class="music-info">
          <div class="music-type text-ellipsis ft14 font-bold">
            {{ item.name }}
          </div>
          <!-- @click="handlePlaylist(item)" -->
        </div>
      </router-link>
    </div>
    <!-- <moreBox class="hidden-md" @handleMore="handleMore"></moreBox> -->
  </div>
</template>

<script>
import musicHandle from "@/mixins/musicHandle";

export default {
  data() {
    return {
      itemHeight:'200px',
    };
  },

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowCollect: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [musicHandle],

  computed: {
    imgBg(url) {
      return (url) => {
        return {
          "background-image": `url(${url})`,
          height: this.itemHeight,
          // 'max-height':'100%',
        };
      };
    },
  },

  created() {
    this.goodType = "songlist";
  },
  mounted() {
    this.itemHeight = `${this.$refs.item.offsetWidth}px`;
    console.log(this.$refs.item.offsetHeight, this.$refs.item.offsetWidth);
  },
  components: {},

  methods: {
    handleCollect(scope) {
      typeof this.goCollectMusic === "function" &&
        this.goCollectMusic(scope, scope.playlist_id, false);
    },
  },
};
</script>


<style lang="scss">
// mobile
@media screen and (max-width: $screen-width-md) {
  .m-bottom-md {
    margin: 0 !important;
  }
  .ant-col-6 {
    width: 30%;
  }
  .fine-music-list {
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    .music-item {
      width: 7rem;
      margin-right: 1rem;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .img-all-box-a {
        width: 100%;
        position: relative;
        .music-play-btn {
          display: none;
          border-radius: 50%;
          background-color: #fff;
          cursor: pointer;
          opacity: 0.8;
          width: 24px;
          height: 24px;
          font-size: 3.2rem;
          color: #000;
          position: absolute;
          top: 50%;
          left: 50%;
          line-height: 24px;
          transform: translate(-50%, -50%);
          cursor: pointer;
          text-align: center;
          padding: 1px 0 0 5px;
        }
        .lable-wrap {
          display: none;
          width: 100%;
          position: absolute;
          left: 10px;
          bottom: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          flex-direction: row;
          flex-wrap: wrap-reverse;
          .lable-item {
            padding: 0 4px;
            margin: 0 8px 4px 0;
            border: 1px solid #fff;
            border-radius: 4px;
          }
        }

        .collect-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 28px;
          line-height: 1;
          color: #fff;

          &.active {
            color: $primary-color;
          }
        }
      }
      .enlarge {
        border-radius: 8px;
        overflow: hidden;
        transition: all 0.4s;
        -moz-transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        background-repeat: no-repeat; /* 不会重复 */
        background-position: center; /* 是为了让图片放大的时候从中部放大，不会偏移 */
        background-size: 100% 100%;
      }
      .enlarge:hover {
        background-size: 120% 120%;
        .music-play-btn {
          display: inline-block;
        }
      }
      margin-bottom: 2.4rem;
      .music-img {
        width: 100%;
      }
      .music-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 11px;
        .music-type {
          color: #000;
          font-size: 12px !important;
        }
        .ant-tag {
          margin-top: 0.3rem;
        }
      }
    }
  }
}
// pc
@media screen and (min-width: $screen-width-md) {
  .fine-music-list {
    width: 100%;
    // height: 228px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .music-item {
      width: 100%;
      // height: 194px;
      margin-right: 1.3%;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .img-all-box-a {
        width: 100%;
        // height: 100%;
        height: 200px;
        position: relative;
        .music-play-btn {
          display: none;
          border-radius: 50%;
          background-color: #fff;
          cursor: pointer;
          opacity: 0.8;
          width: 48px;
          height: 48px;
          font-size: 32px;
          color: #000;
          position: absolute;
          top: 50%;
          left: 50%;
          line-height: 48px;
          transform: translate(-50%, -50%);
          cursor: pointer;
          text-align: center;
          padding: 2px 0 0 3px;
          // background-color: red;
        }
        .lable-wrap {
          position: absolute;
          left: 20px;
          bottom: 20px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          flex-direction: row;
          span {
            flex: 0 0 44;
            // max-width: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 4px;
            margin: 0 8px 4px 0;
            border: 1px solid #fff;
            border-radius: 4px;
          }
        }

        .collect-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 28px;
          line-height: 1;
          color: #fff;

          &.active {
            color: $primary-color;
          }
        }
      }
      .enlarge {
        // background: orange;
        border-radius: 8px;
        overflow: hidden;
        transition: all 0.4s;
        -moz-transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        background-repeat: no-repeat; /* 不会重复 */
        background-position: center; /* 是为了让图片放大的时候从中部放大，不会偏移 */
        background-size: 100% 100%;
      }
      .enlarge:hover {
        background-size: 120% 120%;
        .music-play-btn {
          display: inline-block;
        }
      }
      margin-bottom: 24px;
      .music-img {
        width: 100%;
        max-width: 300px;
        cursor: pointer;
        // height: 310px;
      }
      .music-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 11px;
        .music-type {
          color: #000;
          cursor: pointer;
        }
        .ant-tag {
          margin-top: 3px;
        }
      }
    }
  }
}
</style>
