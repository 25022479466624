<template>
  <a-row class="music-opt">
    <a-col :span=24>
      <a-tooltip title="分享">
        <span class="oper-icon icon-outlined" @click="goShareMusic(videoInfo)">
          <img src="@/assets/images/web/music/share.svg" style="width:18px;"/> 
        </span>
      </a-tooltip>

      <a-tooltip title="收藏">
        <span class="oper-icon con-outlined" v-if="videoInfo.is_collected == '0'" @click="() => {goCollectMusic(videoInfo)}">
          <img src="@/assets/images/web/music/collected.svg" style="width:18px;" v-if="videoInfo.is_collected == '0'" /> 
        </span>
        <span class="oper-icon" v-else @click="() => {goCollectMusic(videoInfo)}">
          <img src="@/assets/images/web/music/collected_done.svg" style="width:18px;" />
        </span>
      </a-tooltip>

      <a-tooltip title="相似">
        <span class="oper-icon con-outlined" @click="goSimilar(videoInfo)" >
          <img src="@/assets/images/web/music/like.svg" style="width:18px;"/> 
        </span>
      </a-tooltip>

      <a-tooltip title="加购">
        <span class="oper-icon icon-bg"  @click="() => {goAddMusicToShopCart(videoInfo)}" >
          <img src="@/assets/images/web/music/cart.svg" style="width:18px;" class="cart_default"/>
          <img src="@/assets/images/web/music/cart_hover.svg" style="width:18px;" class="cart_hover"/> 
        </span>
      </a-tooltip>

      <a-tooltip title="下载">
        <span class="oper-icon icon-bg" @click="() => {goDownloadMusic(videoInfo)}" >
          <img src="@/assets/images/web/music/download.svg" style="width:18px;"/>
          <img src="@/assets/images/web/music/download_hover.svg" style="width:18px;"/> 
        </span>
      </a-tooltip>
    </a-col>
  </a-row>
</template>

<script>
  import {Tooltip} from 'ant-design-vue'
  import musicHandle from '@/mixins/musicHandle'
  import { mapState , mapMutations } from "vuex";

  export default {
    data() {
      return {
        cart_hover_index: -1,
        download_hover_index: -1,
      }
    },
    mixins: [musicHandle],
    props: {
      videoInfo: {
        type: Object,
        default() {
          return {}
        }
      },
      showText: Boolean,
      showHistory:{
        type: Boolean,
        default: false
      },
      showSimilar:{
        type: Boolean,
        default: true
      },
      showShare:{
        type: Boolean,
        default: true
      },
      type: {
        type: String,
        default: 'music'
      }, // 音乐、歌单
    },
    watch: {
      type: {
        handler(newVal) {
          this.goodType = newVal;
        },
        immediate: true
      }
    },

    components: {
      ATooltip: Tooltip,
    },
    computed: {
    ...mapState(["historyMusicDialog"]),
   
  },
    methods: {
    ...mapMutations(["changeHistoryMusicDialog"]),
      openHistoryMusic(){
         if (!this.$root.checkLoginStatus()) return;
        this.changeHistoryMusicDialog(!this.historyMusicDialog)
        setTimeout(() =>{
          document.body.style ={
            overflow: 'auto',
            width: '100%'
          }
        },10)
      }
    }
  }
</script>

<style lang="scss" scoped>
.oper-icon{
  display:inline-block;
  border-radius:2px;
  text-align:center;
  cursor:pointer;
  width:15%;
}
.icon-outlined{
  &:hover{
    opacity: .8;
  }
}
.icon-bg{
  display:inline-block;
  background:#F2F4F5;
  line-height:28px;
  width: 20%;
  margin-left: 5%;
  img{
    display: inline-block;
  }
  img:nth-child(2){
    display: none;
  }
  &:hover{
    background:#404040;
    img:nth-child(2){
      display: inline-block;
    }
    img:nth-child(1){
      display: none;
    }
  }
}
.music-opt{
  img{
    width: 26px;
  }
}
.mini_with{
   width: 22px;
}
</style>
