<template>
  <a-spin :spinning="tLoading">
    <div class="ng-load-page" :class="wrapperClassName" :style="wrapperStyle">
      <template v-if="data && data.length > 0">
        <slot :data="data"></slot>
      </template>
      <template v-else>
        <a-empty class="empty-box"></a-empty>
      </template>

      <template v-if="loadStatus !== Load.empty && loadStatus !== Load.error">
        <template v-if="loadStatus === Load.noMore">
          <!-- <span class="no-more-box font-color-sub flex cen-center active-class">没有更多</span> -->
          <span class="no-more-box font-color-sub flex cen-center active-class"></span>
        </template>

        <template v-else-if="isMore">
          <a-button
            :disabled="loadStatus === Load.loading"
            class="more-box-wrap flex cen-center active-class"
            @click="onClickMore"
          >
            <span>查看更多</span>
            <div class="more-icon-box flex cen-center">
              <icon-font type="iconarrow-line-bottom"></icon-font>
            </div>
          </a-button>
        </template>
      </template>
    </div>
  </a-spin>
</template>

<script>
  import {Empty, Spin} from 'ant-design-vue';
  import {Load} from '@/utils/variables';

  export default {
    data() {
      return {
        tLoading: false,
        newParams: {},
        Load,
        data: [],
        loadStatus: Load.init,
      }
    },

    props: {
      request: Function,
      params: Object,
      reload: Boolean,
      // 数据请求之后，转换数据
      transformData: Function,
      changeData: {
        type: Array,
        default() {
          return []
        }
      },
      keyId: String,
      dataList: Array,
      isMore: {
        type: Boolean,
        default: true
      },
      wrapperClassName: String,
      wrapperStyle: {
        type: Object,
        default() {
          return {}
        }
      }
    },

    watch: {
      params: {
        handler(newVal) {
          let params = Object.assign(this.newParams, newVal);

          this.newParams = params;

          this.newParams.page = 1;

          this.getData(this.newParams, true);
        },
        deep: true,
        immediate: true
      },

      dataList: {
        handler(newVal) {
          if (!this.request || typeof this.request !== 'function') {
            typeof this.transformData === 'function' && (newVal = this.transformData(newVal));

            this.data = newVal || []
          }
        },
        immediate: true
      },

      reload(newVal) {
        if (newVal) {
          this.reloadData();

          this.$emit('update:reload', false);
        }
      },

      changeData(newVal) {
        if (newVal && newVal.length > 0) {
          let list = this.data;

          let changeDir = newVal.reduce((prev, next) => {
            let key = next[this.keyId];
            key && (prev[next[this.keyId]] = next);
            return prev;
          }, {})

          list = list.map(item => {
            let key = item[this.keyId];

            return key && changeDir[key] ? changeDir[key] : item;
          })

          this.data = [...list];
        }
      }
    },


    components: {
      AEmpty: Empty,
      ASpin: Spin
    },

    methods: {

      onClickMore() {
        this.newParams.page += 1;

        this.getData(this.newParams);
      },

      reloadData() {
        this.newParams.page = 1;

        this.getData(this.newParams, true);
      },

      getData(params, reload) {
        if (typeof this.request === 'function') {
          this.tLoading = true;

          this.loadStatus = Load.loading;

          this.request(params).then(res => {
            let data = res.data;

            if (data && data.code == 0) {
              let inner = data.data;
              let total = inner.total, pagesize = inner.page_size, page = this.newParams.page;
              let curTotal = pagesize * page;

              let list = data.data ? data.data.list : [];

              typeof this.transformData === 'function' && (list = this.transformData(list));

              this.data = !reload ? this.data.concat(list) : list;

              this.loadStatus = total == 0 ? Load.empty : total <= curTotal ? Load.noMore : Load.load;

              this.$emit('dataChange', this.data);
            } else {
              this.loadStatus = Load.error;
              this.newParams.page -= 1;
            }

            this.tLoading = false;
          }).catch(() => {
            this.loadStatus = Load.error;
            this.tLoading = false;
            this.newParams.page -= 1;
          })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-load-page {
    min-height: 200px;
  }

  .no-more-box {
    margin-top: 16px;
  }

  .more-box-wrap {
    display: block;
    margin: 24px auto 0;
    cursor: pointer;
    width: 150px;
    text-align: center;
    border: 0;
    background:#F9FAFA;

    .more-icon-box {
      display: inline-block;
      width: 36px;
      height: 36px;
      line-height: 36px;
      color: #fff;
      text-align: center;
      margin-left: 8px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50% 50%;
    }

    &[disabled] {
      background: none;

      .more-icon-box {
        background: rgba(0, 0, 0, 0.45);

        &:hover {
          background: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }

  .empty-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
