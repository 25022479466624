<template>
    <div class="content-box">
        <div class="cont-header">
           <span :selected="selType==1" @click="onClickSel(1)">音乐收藏</span>
           <span :selected="selType==4" @click="onClickSel(4)">音效收藏</span>
           <span :selected="selType==2" @click="onClickSel(2)">歌单收藏</span>
        </div>
        <div class="cont">
            <template v-if="selType === 1">
               <music-table
                :request="$axios.Static_deedList"
                :params="musicTableParams"
                :reload="reloadTable"
                @success="onSuccessTable"
                :pagination="pagination"
                :is-more="false" 
                :selecteLabelIds=[]
            ></music-table>
            </template>
            <template v-else-if="selType === 4">
               <music-table
                :request="$axios.Static_deedList"
                :params="soundTableParams"
                :reload="reloadTable"
                @success="onSuccessTable"
                :pagination="pagination"
                :is-more="false" 
                :selecteLabelIds=[]
            ></music-table>
            </template>
            <template v-else-if="selType=== 2">
                <ng-load-page
                    class="m-top-base song-list-box"
                    keyId="playlist_id"
                    :request="$axios.Static_deedList"
                    :params="songListParams"
                    :changeData="changeItem"
                    :reload.sync="reloadSonglist"
                >
                    <template v-slot:default="{data}">
                        <a-row :gutter="20">
                            <a-col
                            v-for="item in data"
                            class="m-bottom-md"
                            :xs="12"
                            :sm="12"
                            :md="6"
                            :key="item.playlist_id"
                            >
                            <song-list-item :item="item" isShowCollect @update="updateListItem"></song-list-item>
                            </a-col>
                        </a-row>
                    </template>
                </ng-load-page>
                </template>
        </div>
    </div>
    
</template>
<script>
    import MusicTable from '@/pages/components/music/NewMusicTable.vue'
    import NgLoadPage from '@/components/NgLoadPage.vue'
    import SongListItem from '@/pages/components/songList/SongListItem.vue'
    export default {
        data() {
            return {
                selType: 1,
                musicTableParams: {},
                reloadTable: false,
                pagination: {
                    "show_pagin" : true,
                    "show-quick-jumper": true,
                    "default-current":1,
                    total:0,
                    pageSize: 20,
                    current: 0,
                    onChange: this.onChangePage,
                    hideOnSinglePage: true,
                    style:{
                        "float": "none",
                        "text-align": "center",
                    },
                },
                songListParams: {},
                changeItem: [],
                reloadSonglist: false,
            }
        },
        components: {
            MusicTable,
            NgLoadPage,
            SongListItem
        },
        created: function () {
           this.musicTableParams = {obj_type: 1, deed_type: 1};
           this.songListParams = {obj_type: 2, deed_type: 1};
           this.soundTableParams = {obj_type: 4, deed_type: 1};
        },
        methods: {
            onClickSel(idx){
                this.selType = idx;
            },
            onSuccessTable() {
                this.reloadTable = true;
            },
            updateListItem() {
                this.reloadSonglist = true;
            },
            onChangePage(params) {
                this.pagination.current = params;
                this.pagination = Object.assign({}, this.pagination);
                document.documentElement.scrollTop = 0;
            },
        },
    }
</script>
<style lang="scss" scoped>  
    ::v-deep {
        table {
            td:nth-child(3) {
                display: none;
            }
        }
        table colgroup {
            col:nth-child(1){
                width: 60px !important;
                min-width:60px !important;
            }
            col:nth-child(2){
                width: 180px !important;
                min-width:180px !important;
            }
            col:nth-child(3){
                width: 60px !important;
                min-width:60px !important;
            }
            col:nth-child(4){
                width: 180px !important;
                min-width:180px !important;
            }
        }
        .more-box-wrap{
            background: #fff;
        }
    }
    .cont-header{
        height: 66px;
        border-bottom:1px solid #F0F4F5;
        span{
            display: inline-block;
            line-height: 66px;
            text-align:center;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-left: 10px;
        }
        span:nth-child(1){
            margin-left: 39px;
        }
        span:nth-child(2), span:nth-child(3){
            margin-left: 45px;
        }
        span[selected]{
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
        span:hover{
            cursor: pointer;
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
    }
    .cont{
        width: 90%;
        margin: 0 auto;
        padding-top:24px;
    }
</style>