import { render, staticRenderFns } from "./NgLoadPage.vue?vue&type=template&id=16a86806&scoped=true&"
import script from "./NgLoadPage.vue?vue&type=script&lang=js&"
export * from "./NgLoadPage.vue?vue&type=script&lang=js&"
import style0 from "./NgLoadPage.vue?vue&type=style&index=0&id=16a86806&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a86806",
  null
  
)

export default component.exports